import styled from "styled-components";

export const HeaderContaiener = styled.header`
   width: 100%;
   max-width: 1900px;
   height: 120px;
   position: fixed;
   top: 0;
   padding: 0 20px;
   background-color: #E1A6A0;
   box-shadow: 0 0 10px #00000059;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 10;

   @media (max-width: 950px) {
      justify-content: center;
      height: 100px;
      padding: 0;
   }
`;

export const LogoContent = styled.div`
   width: 30%;
   display: flex;
   align-items: center;
   justify-content: center;

   @media (max-width: 950px) {
      width: 100%;
   }
`;

export const Logo = styled.div`
   width: auto;
   height: 120px;
   position: relative;
   left: 40px;

   @media (max-width: 1115px) {
      height: 90px;
   }

   @media (max-width: 950px) {
      left: 0;

      &:first-child {
         display: none;
      }
   }
`;

export const Img = styled.img`
   width: auto;
   height: 100%;
   object-fit: cover;
`;

export const NavHeader = styled.nav`
   width: 60%;

   @media (max-width: 950px) {
      display: none;
   }
`;

export const UlHeader = styled.ul`
   width: 100%;
   max-width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   list-style: none;
`;

export const LiHeader = styled.li`
   position: relative;
   width: auto;
   max-width: 125px;
`;

export const LinkHeader = styled.a`
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  transition: .35s all;

  &:hover {
      color: #C39B6A;
      transition: .35s all;
   }

   @media (max-width: 1115px) {
      font-size: 14px;
   }

   @media (max-width: 950px) {
      display: none;
   }
`;