import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    height: auto;
    padding: 10px;
`;

export const AdvantagesContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    border-bottom: 2px solid #E1A6A0;

    @media (max-width: 900px) {
        flex-wrap: wrap;
        border: none;
        align-items: center;
    }
`;

export const Cards = styled.div`
    width: 25%;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
    padding: 5px;

    &:nth-child(1) {
        border-right: 2px solid #E1A6A0;
    }

    &:nth-child(2) {
        border-right: 2px solid #E1A6A0;
    }

    &:nth-child(3) {
        border-right: 2px solid #E1A6A0;
    }

    div:first-child {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
    }

    div:last-child {
        width: 100%;
        padding: 10px;

        p {
            font-size: 25px;
            color: #E1A6A0;
            text-transform: uppercase;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            padding-top: 10px;
        }

        span {
            color: #8E8E8E;
            font-size: 16.5px;
            font-weight: 500;
            font-family: "Montserrat", sans-serif;
        }
    }

    @media (max-width: 1120px) {
        div:last-child {
            p {
                font-size: 20px;
            }

            span {
                color: #8E8E8E;
                font-size: 13px;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;
            }
        }
    }

    @media (max-width: 900px) {
        width: 50%;

        &:nth-child(1) {
            border-bottom: 2px solid #E1A6A0;
        }

        &:nth-child(2) {
            border-right: none;
            border-bottom: 2px solid #E1A6A0;
        }

        &:nth-child(3) {
            padding-top: 30px;
            border-right: 2px solid #E1A6A0;
        }

        &:nth-child(4) {
            border-right: none;
            padding-top: 30px;
        }
    }

    @media (max-width: 480px) {
        div:first-child {
            height: 60px;
        }
        
        div:last-child {
            p {
                font-size: 12px;
            }

            span {
                color: #8E8E8E;
                font-size: 10.5px;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
`;