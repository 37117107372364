import styled from "styled-components";

export const TeamContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    padding: 50px 20px 5px;

    @media (max-width: 950px) {
        padding: 0;
        margin-top: 0;
    }
`;

export const TeamTitle = styled.div`
    width: 100%;
    height: 150px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #E1A6A0;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
`;

export const CardsTeamContent = styled.div`
    width: 100%;
    height: 550px;

    .swiper {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 5px;
    
        .swiper-slide {
            width: 33%;
        }
    }
`;

export const CardsTeam = styled.div`
    width: 100%;
    height: 500px;
    display: block;
`;

export const CardName = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #E1A6A0;
    font-size: 25px;
    text-align: center;

    @media (max-width: 950px) {
        font-size: 16px;
    }
`;

export const CardImage = styled.div`
    width: 100%;
    height: 270px;
    display: flex;
    align-items: start;
    justify-content: center;

    .avatar-img {
        height: 100%;
        width: auto;
        object-fit: cover;
        box-shadow: 0px 0px 5px #00000059;
        border-radius: 50%;
    }

    @media (max-width: 950px) {
        height: 200px;
    }
`;

export const Avatar = styled.div`
    width: 270px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    box-shadow: 0px 0px 5px #00000059;
    border-radius: 50%;

    .avatar-img-off {
        height: 80px;
        width: 80px;
    }

    @media (max-width: 950px) {
        width: 210px;
    }
`;

export const CardsInformations = styled.div`
   width: 100%;
   height: 80px;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   flex-direction: column;
`;

export const InformationTeam = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8E8E8E;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    gap: 5px;
    cursor: default;

    @media (max-width: 950px) {
        font-size: 13px;
    }
`;

export const ButtonCatalog = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        width: 200px;
        height: 50px;
        border: none;
    }

    button a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E1A6A0;
        gap: 5px;
        font-size: 20px;
        color: #fff;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        transition: all .25s ease-in-out;
    }

    button a:hover {
        background-color: #e1a6a0d6;
        transition: all .25s ease-in-out;
    }

    button p {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1a6a07d;
        font-size: 15px;
        color: #fff;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        cursor: default;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        transition: all .25s ease-in-out;
    }
`;
