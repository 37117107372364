import styled from "styled-components";

export const AboutContainer = styled.div`
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    @media (max-width: 1000px) {
        flex-direction: column;
        height: auto;
        padding: 10px 0;
    }
`;

export const Content = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        width: 100%;
        height: auto;
        justify-content: start;

        &:first-child {
            order: 2;
            padding: 20px 0;
        }

        &:last-child {
            order: 1;
        }
    }
`;

export const AboutTexts = styled.div`
    width: 80%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1300px) {
        height: 75%;
    }

    @media (max-width: 1000px) {
        width: 90%;
        padding: 20px 0;
        height: auto;
    }
`;

export const Title = styled.span`
    padding-bottom: 30px;
    text-align: center;
    font-size: 35px;
    color: #E1A6A0;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;

    @media (max-width: 950px) {
        font-size: 25px;
    }
`;

export const ContentInformations = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: #8E8E8E;
    text-align: justify;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;

    @media (max-width: 950px) {
        font-size: 15px;
    }
`;

export const ButtonMoreInfo = styled.button`
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #E1A6A0;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;

    &:hover {
        background-color: #E1A6A0;
    }  
`;

export const AboutImg = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Banner = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;