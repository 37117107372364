import { 
    HeaderContaiener,
    LogoContent,
    Logo,
    Img,
    NavHeader,
    UlHeader,
    LiHeader,
    LinkHeader
} from "./styles";
import Logo_at from "../../assets/AnaTheiss_Logo_at.png";
import Logo_full from "../../assets/AnaTheiss_Logo_full.png";

export default function Header({
    linkAbout,
    linkConsigned,
    linkWholesale,
    linkGuarantee,
    linkTeam,
    linkContact,
    onClick
}) {
    return (
        <HeaderContaiener>
            <LogoContent>
                <Logo>
                    <a href="/">
                        <Img src={Logo_at} alt="" />
                    </a>
                </Logo>
                <Logo>
                    <a href="/">
                        <Img src={Logo_full} alt="" />
                    </a>
                </Logo>
            </LogoContent>
            <NavHeader>
                <UlHeader>
                    <LiHeader>
                        <LinkHeader href={linkAbout} onClick={onClick}>Sobre nós</LinkHeader>
                    </LiHeader>
                    <LiHeader>
                        <LinkHeader href={linkConsigned} onClick={onClick}>Consignado</LinkHeader>
                    </LiHeader>
                    <LiHeader>
                        <LinkHeader href={linkWholesale} onClick={onClick}>Atacado</LinkHeader>
                    </LiHeader>
                    <LiHeader>
                        <LinkHeader href={linkGuarantee} onClick={onClick}>Garantia</LinkHeader>
                    </LiHeader>
                    <LiHeader>
                        <LinkHeader href={linkTeam} onClick={onClick}>Vendedoras</LinkHeader>
                    </LiHeader>
                    <LiHeader>
                        <LinkHeader href={linkContact} onClick={onClick}>Contato</LinkHeader>
                    </LiHeader>
                </UlHeader>
            </NavHeader>
        </HeaderContaiener>
    );
}