import styled from "styled-components";

export const ConsignedContainer = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1A6A0;

    @media (max-width: 767px) {
        height: auto;
        flex-direction: column;
    }
`;

export const ContentText = styled.div`
    width: 40%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
`;

export const ConsignedTexts = styled.div`
    width: 80%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
        width: 90%;
        align-items: center;
    }
`;

export const Title = styled.span`
    padding-bottom: 10px;
    text-align: left;
    font-size: 35px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;

    @media (max-width: 1000px) {
        font-size: 25px;
    }

    @media (max-width: 767px) {
        text-align: center;
        padding: 20px;
        font-size: 17px;

        br {
            display: none;
        }
    }
`;

export const ContentInformations = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    text-align: justify;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;

    @media (max-width: 1000px) {
        font-size: 14px;
    }

    @media (max-width: 767px) {
        text-align: center;
        padding-bottom: 20px;
    }
`;

export const ButtonMoreInfo = styled.button`
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #ffffff;
    color: #fff;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        background-color: #ffffff;
    }  
    
    a {
        text-decoration: none;
        color: #E1A6A0;
        font-weight: 600;
        width: 100%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ContentInputs = styled.div`
    width: 60%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        width: 100%;
    }
`;

export const Form = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ContentForm = styled.div`
    width: 80%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`;

export const Input = styled.div`
    position: relative;
    width: 45%;
    height: 35px;
    text-transform: uppercase;

    input {
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        padding: 8px;
        color: #fff;
        font-family: "Montserrat", sans-serif;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: rgba(255, 255, 255, 0.715);
        text-transform: uppercase;
        pointer-events: none;
        transition: .5s;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
    }

    input:valid ~ label,
    input:focus ~ label {
        color: rgba(255, 255, 255, 1);
        transform: translateY(-15px);
        font-size: .6rem;
    }

    i {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 1);
    }

    i:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        animation: animate 3s linear infinite;
    }

    @media (max-width: 767px) {
        width: 95%;
    }
`;

export const TextArea = styled.div`
    position: relative;
    width: 95%;
    height: 100px;
    text-transform: uppercase;

    textArea {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        background: transparent;
        outline: none;
        border: none;
        color: #fff;
        padding: 8px;
        resize: none;
        font-family: "Montserrat", sans-serif;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: rgba(255, 255, 255, 0.715);
        text-transform: uppercase;
        pointer-events: none;
        transition: .5s;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
    }

    textArea:valid ~ label,
    textArea:focus ~ label {
        color: rgba(255, 255, 255, 1);
        transform: translateY(-15px);
        font-size: .6rem;
    }

    i {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #222;
    }

    i:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        animation: animate 3s linear infinite;
    }
`;

export const ButtonSend = styled.button`
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #ffffff;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    color: #E1A6A0;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
        background-color: #ffffff;
    } 
`;