import { useEffect, useState } from 'react';
import {
    TeamContent,
    CardsTeamContent,
    CardsTeam,
    CardName,
    CardImage,
    CardsInformations,
    ButtonCatalog,
    InformationTeam,
    Avatar,
    TeamTitle
} from './styles';
import fetchData from '../../api/config-api';
import { MapPin, Phone, ArrowDownToLine } from 'lucide-react';
import ImageOff from '../../assets/AnaTheiss_image_off.png';

import { Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Team() {

    const [records, setRecords] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const apiData = await fetchData();
                if (Array.isArray(apiData.records)) {
                    setRecords(apiData.records);
                } else {
                    console.error('Os dados não contêm um array.');
                }
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        getData();
    }, [])

    if (!records.length === 0) {
        <p>Carregando...</p>
    }

    return (
        <TeamContent>
            <TeamTitle>
                Vendedoras
            </TeamTitle>
            <CardsTeamContent>
                <Swiper 
                    modules={[Navigation]}
                    spaceBetween={30}
                    breakpoints={{
                        300:{
                            slidesPerView: 1, 
                        },
                        465:{
                            slidesPerView: 2,
                        },
                        800:{
                            slidesPerView: 3,
                        },
                    }}
                    navigation={ true }
                >
                    {records.map((item, index) => (
                        item.status === 'Active' && (
                            <SwiperSlide key={index} className='swiper-slide'>
                                <CardsTeam>
                                    <CardName>
                                        <p>{item.name}</p>
                                    </CardName>
                                    <CardImage>
                                        {item.avatar ? 
                                            <img className='avatar-img' src={item.avatar} alt='' /> 
                                            : 
                                            <Avatar>
                                                <img className='avatar-img-off' src={ImageOff} alt='' />
                                            </Avatar>
                                        }
                                    </CardImage>
                                    <CardsInformations>
                                        <InformationTeam>
                                            <Phone color='#E1A6A0' size={18} />
                                            <p>{item.cellphone}</p>
                                        </InformationTeam>
                                        <InformationTeam>
                                            <MapPin color='#E1A6A0' size={18} />
                                            {item.city ?
                                                <p>{item.city}/{item.state}</p>
                                                :
                                                <p>Não cadastrado</p>
                                            }
                                        </InformationTeam>
                                    </CardsInformations>
                                    <ButtonCatalog>
                                        <button 
                                            title={item.catalog_url ? 'Ver Catálogo' : 'Catálogo Indisponível no Momento'}
                                            aria-label={item.catalog_url ? 'Ver Catálogo' : 'Catálogo Indisponível no Momento'} 
                                        >
                                            {item.catalog_url ? 
                                                <a href={item.catalog_url} target='_blank' rel="noreferrer">
                                                    <ArrowDownToLine size={16} />
                                                    catálogo
                                                </a>
                                                :
                                                <p>catálogo indisponível</p>
                                            }
                                        </button>
                                    </ButtonCatalog>
                                </CardsTeam>
                            </SwiperSlide>                
                        )
                    ))}
                </Swiper>
            </CardsTeamContent>
        </TeamContent>
    );
}