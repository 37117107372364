import { 
    BannerContainer,
    BannerContent,
    Banners,
    BannerImg,
    BannerText, 
} from "./styles";
import Banner_01 from "../../assets/AnaTheiss_banner_01.png";
import Banner_02 from "../../assets/AnaTheiss_banner_02.png";
import Banner_03 from "../../assets/AnaTheiss_banner_03.png";

export default function Banner() {

    return (
        <BannerContainer>
            <BannerContent>
                <Banners>
                    <BannerImg src={Banner_01} alt="" />
                    <BannerText>Elegância</BannerText>
                </Banners>
            </BannerContent>

            <BannerContent>
                <Banners>
                    <BannerImg src={Banner_02} alt="" />
                    <BannerText>Qualidade</BannerText>
                </Banners>
            </BannerContent>

            <BannerContent>
                <Banners>
                    <BannerImg src={Banner_03} alt="" />
                    <BannerText>Sofisticação</BannerText>
                </Banners>
            </BannerContent>

            <BannerContent>
                <Banners>
                    <BannerImg src={Banner_01} alt="" />
                    <div className="banner-text">
                        <BannerText>Elegância</BannerText>
                        <BannerText>Qualidade</BannerText>
                        <BannerText>Sofisticação</BannerText>
                    </div>
                </Banners>
            </BannerContent>

        </BannerContainer>
    )
}