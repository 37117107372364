import styled from "styled-components";

export const ContactContainer = styled.div`
    width: 100%;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    @media (max-width: 1300px) {
        flex-direction: column;
        height: auto;
    }
`;

export const Content = styled.div`
    width: 50%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    form {
        width: 100%;
        height: 80%;
        padding: 15px 0 0;
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;

        @media (max-width: 1300px) {
            height: 550px;
            justify-content: center;
        }
    }

    @media (max-width: 1300px) {
        width: 100%;
        height: auto;
    }
`;

export const ContactBanner = styled.div`
    width: 100%;
    height: auto;
    background-color: #E1A6A0;
    object-fit: contain;
    
    @media (max-width: 1300px) {
        img{
            width: 100%;
            height: 350px;
            object-fit: none;
        }
    }

    @media (max-width: 767px) {
        img {
            width: 80%;
            height: auto;
            object-fit: cover;
        }
    }
`;

export const ContentInputs = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-wrap: wrap;
`;

export const Input = styled.div`
    position: relative;
    width: 45%;
    height: 35px;
    text-transform: uppercase;

    input {
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        padding: 8px;
        color: #222;
        font-family: "Montserrat", sans-serif;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: #E1A6A0;
        text-transform: uppercase;
        pointer-events: none;
        transition: .5s;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
    }

    input:valid ~ label,
    input:focus ~ label {
        color: #E1A6A0;
        transform: translateY(-15px);
        font-size: .6rem;
    }

    i {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.5px;
        background: #E1A6A0;
    }

    i:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #E1A6A0;
        animation: animate 3s linear infinite;
    }

    @media (max-width: 767px) {
        width: 95%;
    }
`;

export const TextArea = styled.div`
    position: relative;
    width: 95%;
    height: 100px;
    text-transform: uppercase;

    textArea {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        background: transparent;
        outline: none;
        border: none;
        color: #222;
        padding: 8px;
        resize: none;
        font-family: "Montserrat", sans-serif;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: #E1A6A0;
        text-transform: uppercase;
        pointer-events: none;
        transition: .5s;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
    }

    textArea:valid ~ label,
    textArea:focus ~ label {
        color: #E1A6A0;
        transform: translateY(-15px);
        font-size: .6rem;
    }

    i {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.5px;
        background: #222;
    }

    i:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #E1A6A0;
        animation: animate 3s linear infinite;
    }
`;

export const ButtonSend = styled.button`
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #E1A6A0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    
    &:hover {
        background-color: #E1A6A0;
    } 
`;

export const ContactInfo = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 950px) {
        flex-direction: column;
        gap: 30px;
        padding: 10px 0;
    }
`;

export const Infos = styled.div`
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:last-child {
        display: none;

        @media (max-width: 950px) {
            display: flex;
            cursor: pointer;
        }
    }
`;

export const InfoIcon = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InfoTexts = styled.div`
    height: 70px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #D9D9D9;

    @media (max-width: 950px) {
        font-size: 14px;
    }
`;