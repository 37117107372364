import {
    Container,
    ImgContent,
    ButtonClose,
} from './styles';
import BannerRevenue01 from '../../assets/AnaTheiss_garantia.png';
import BannerRevenue02 from '../../assets/AnaTheiss_revenue_modal.png';
import { X } from 'lucide-react';

export default function RevenueModal({closeModal}) {
    return (
        <>
        <Container className='teste'>
            <div className='revenue-images'>
                <ImgContent>
                    <img src={BannerRevenue01} alt="" />
                </ImgContent>
                <ImgContent>
                    <img src={BannerRevenue02} alt="" />
                </ImgContent>
            </div>
            
            <ButtonClose onClick={closeModal}>
                <X color='#fff' size={18} />
            </ButtonClose>
            
        </Container>
    </>

    )
}