import {
    ContactContainer,
    Content,
    ContactBanner,
    ContentInputs,
    Input,
    TextArea,
    ButtonSend,
    ContactInfo,
    Infos,
    InfoIcon,
    InfoTexts
} from './styles';
import Banner from '../../assets/AnaTheiss_banner_contact.png';
import WhatsappLogo from '../../assets/AnaTheiss_whatsapp_logo.png';
import { Instagram, Mail } from 'lucide-react';
import { useState } from 'react';

export default function Contact() {

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 3) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
        }
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === 'tel' ? formatPhoneNumber(value) : value;
        setFormData({
            ...formData,
            [name]: formattedValue
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, tel, message } = formData;
        const customMessage = `Olá, me chamo ${name}\nInformações de contato:\nEmail: ${email}\nTelefone: ${tel}\nMensagem: ${message}`;
        const encodedMessage = encodeURIComponent(customMessage);
        const url = `https://api.whatsapp.com/send/?phone=5547984043160&text=${encodedMessage}&type=phone_number&app_absent=0`;
        window.location.href = url;     
    }

    return (
        <ContactContainer>
            <Content>
                <ContactBanner>
                    <img src={Banner} alt='' />
                </ContactBanner>
            </Content>

            <Content>
                <form onSubmit={handleSubmit}>
                    <ContentInputs>
                        <Input>
                            <input 
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="name">Nome</label>
                            <i></i>
                        </Input>
                        <Input>
                            
                            <input 
                                type="text"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="tel">Telefone</label>
                            <i></i>
                        </Input>
                        <Input style={{ width: '95%' }}>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="email">E-mail</label>
                            <i></i>
                        </Input>
                        <TextArea>
                            <textarea 
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="message">Mensagem</label>
                            <i></i>
                        </TextArea>
                    </ContentInputs>
                    <ButtonSend type="submit">
                        Enviar
                    </ButtonSend>
                </form>
                <ContactInfo>
                    <Infos>
                        <InfoIcon>
                            <Mail size={40} color='#E1A6A0' strokeWidth={1.7}/>
                        </InfoIcon>
                        <InfoTexts>
                            <p>financeiro@atsemijoias.com.br</p>
                            <p>contato@atsemijoias.com.br</p>
                            <p>atendimento@atsemijoias.com.br</p>
                        </InfoTexts>
                    </Infos>

                    <Infos>
                        <InfoIcon>
                            <img src={WhatsappLogo} alt='' />
                        </InfoIcon>
                        <InfoTexts>
                            <p>47 98404-3160</p>
                        </InfoTexts>
                    </Infos>

                    <Infos>
                        <InfoIcon>
                            <a href='href="https://www.instagram.com/anatheisssemijoias/' target="_blank" rel="noreferrer">
                                <Instagram size={40} color='#E1A6A0' strokeWidth={1.7}/>
                            </a>
                        </InfoIcon>
                    </Infos>
                </ContactInfo>
            </Content>
        </ContactContainer>
    );
}
