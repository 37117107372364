import { 
  Container, 
  Section, 
} from './styles';
import Modal from 'react-modal';
import { useState } from 'react';

import Banner from './components/banner';
import Header from './components/header';
import About from './components/about';
import Consigned from './components/consigned';
import Wholesale from './components/wholesale';
import Team from './components/team';
import Contact from './components/contact';
import Footer from './components/footer';

import SalesModal from './components/modais/salesModal';
import RevenueModal from './components/modais/revenueModal';
import AboutModal from './components/modais/aboutModal';
import Advantages from './components/advantages';

function App() {

  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const headerOffset = 100;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  const [showModalGuarantee, setShowModalGuarantee] = useState(false);
  const [showModalSales, setShowModalSales] = useState(false);
  const [showModalAbout, setShowModalAbout] = useState(false);

  function handleOpenModalGuarantee() {
    setShowModalGuarantee(true);
  }
  
  function handleOpenModalAbout() {
    setShowModalAbout(true);
  }

  function handleOpenModalSales() {
    setShowModalSales(true);
  }

  function closeModal() {
    setShowModalGuarantee(false);
    setShowModalSales(false);
    setShowModalAbout(false);
  }

  return (
    <Container>
      <Header
        linkAbout="#about"
        linkConsigned="#consigned"
        linkWholesale="#wholesale"
        linkGuarantee="#wholesale"
        linkTeam="#team"
        linkContact="#contact"
        onClick={handleScroll}
      />
      <Banner />
      <Advantages/>
      <Section id="about">
        <About openModalAbout={handleOpenModalAbout}/>
      </Section>

      <Section id="consigned">
        <Consigned/>
      </Section>
      
      <Section id="wholesale">
        <Wholesale
          onClickWholesale={handleOpenModalSales}
          onClickGuarantee={handleOpenModalGuarantee}
        />
      </Section>
  
      <Section id="team">
        <Team/>
      </Section>
      <Section id="contact">
        <Contact/>
      </Section>
      <Footer
        linkAbout="#about"
        linkConsigned="#consigned"
        linkWholesale="#wholesale"
        linkGuarantee="#wholesale"
        linkTeam="#team"
        linkContact="#contact"
        onClick={handleScroll}
      />
      <Modal
        isOpen={showModalSales}
        contentLabel="Informações de vendas"
        style={customStyles}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
      >
        <SalesModal
          closeModal={closeModal}
        />
      </Modal>
      <Modal
        isOpen={showModalGuarantee}
        contentLabel="Receitinha"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <RevenueModal closeModal={closeModal}/>
      </Modal>
      <Modal
        isOpen={showModalAbout}
        contentLabel="Sobre Nós"
        style={customStylesAbout}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <AboutModal closeModal={closeModal}/>
      </Modal>
    </Container>
  );
}

const customStyles = {
  content: {
    background: 'transparent',
    border: 'none',
    width: 'auto',
    inset: '0',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.875)',
    zIndex: '999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const customStylesAbout = {
  content: {
    background: 'transparent',
    border: 'none',
    width: 'auto',
    inset: '0',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.875)',
    zIndex: '999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default App;