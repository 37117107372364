import styled from "styled-components";

export const BannerContainer = styled.div`
    width: 100%;
    padding-top: 120px;
    height: 100%;
    display: flex;

    @media (max-width: 950px) {
        padding-top: 100px;
    }
`;

export const BannerContent = styled.div`
    flex-grow: 1;
    width: 33%;

    &:last-child {
        display: none;
    }

    @media (max-width: 767px) {
        width: 100%;
        display: none;

        &:last-child {
            display: flex;
        }
    }
`;

export const Banners = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-text {
        position: absolute;
    }
`;

export const BannerImg = styled.img`
    object-fit: cover;
    filter: brightness(70%) saturate(84%);
    -webkit-filter: brightness(70%) saturate(84%);
    -moz-filter: brightness(70%) saturate(84%);
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
`;

export const BannerText = styled.div`
    position: absolute;
    color: #ffffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 767px) {
        padding: 35px 0;
        position: relative;
    }
`;

