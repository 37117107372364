import {
    Container,
    ImgContent,
    ButtonClose
} from './styles';
import BannerSales from '../../assets/AnaTheiss_sales_modal.png';
import { X } from 'lucide-react';

export default function RevenueModal({closeModal}) {
    return (
        <Container> 
            <ImgContent className='img-sale'>
                <img src={BannerSales} alt="" />
            </ImgContent>
            <ButtonClose onClick={closeModal} className='btn-sale'>
                <X color='#fff' size={18}/>
            </ButtonClose>
        </Container>
    )   
}