import styled from "styled-components";

export const Container = styled.main`
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;

    #team {
        max-height: auto;
    }
`;

export const Section = styled.section`
    width: 100%;
    height: auto;
`;