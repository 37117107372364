import {
    ConsignedContainer,
    ConsignedTexts,
    Title,
    ContentText,
    ContentInformations,
    ButtonMoreInfo,
    ContentInputs,
    Form,
    ContentForm,
    Input,
    TextArea,
    ButtonSend
} from "./styles";
import { useState } from "react";

export default function Consigned() {

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 3) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
        }
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === 'tel' ? formatPhoneNumber(value) : value;
        setFormData({
            ...formData,
            [name]: formattedValue
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, tel, message } = formData;
        const customMessage = `Olá, quero ser uma vendedora:\n\nNome: ${name}\nEmail: ${email}\nTelefone: ${tel}\nMensagem: ${message}`;
        const encodedMessage = encodeURIComponent(customMessage);
        const url = `https://api.whatsapp.com/send/?phone=5547984043160&text=${encodedMessage}&type=phone_number&app_absent=0`;
        window.location.href = url;
    }

    return (
        <ConsignedContainer>
            <ContentText>
                <ConsignedTexts>
                    <Title>Seja uma<br /> Revendedora<br /> de consignado</Title>
                    <ContentInformations>
                        Para você se tornar uma revendedora ANA THEISS SEMIJOIAS é fundamental que você seja uma pessoa honesta, ética, comprometida e esforçada.
                    </ContentInformations>
                </ConsignedTexts>
                <ButtonMoreInfo>
                    <a href="https://t9013292863.p.clickup-attachments.com/t9013292863/6a607690-8d37-4c67-bae0-0a3857161f45/Nova%20revendedora%20-%20Ana%20Theiss%20Semi%20Joias.pdf?view=open" target="_blank" rel="noreferrer">+ informações</a>
                </ButtonMoreInfo>
            </ContentText>

            <ContentInputs>
                <Form onSubmit={handleSubmit}>
                    <ContentForm>
                        <Input>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="name">Nome</label>
                            <i></i>
                        </Input>
                        <Input>
                            <input
                                type="text"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="tel">Telefone</label>
                            <i></i>
                        </Input>
                        <Input style={{ width: '95%' }}>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required="required"
                                autoComplete="off"
                            />
                            <label htmlFor="email">E-mail</label>
                            <i></i>
                        </Input>
                        <TextArea>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required="required"
                            />
                            <label htmlFor="message">Mensagem</label>
                            <i></i>
                        </TextArea>
                    </ContentForm>
                    <ButtonSend type="submit">
                        Enviar
                    </ButtonSend>
                </Form>
            </ContentInputs>
        </ConsignedContainer>
    );
}