import {
    Container,
    ImgContent,
    ButtonClose
} from './styles';

import BannerAboutDesktop from '../../assets/AnaTheiss_about_modal.png';
import BannerAboutMobile from '../../assets/AnaTheiss_about_modal_mobile.png';

import { X } from 'lucide-react';

export default function AboutModal({closeModal}) {
    return (
        <Container> 
            <ImgContent>
                <img className='img-desktop' src={BannerAboutDesktop} alt="" />
                <img className='img-mobile' src={BannerAboutMobile} alt="" />
                <button>
                    <a href="https://api.whatsapp.com/send/?phone=5547984043160&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                        Quero agora
                    </a>
                </button>
            </ImgContent>
            <ButtonClose onClick={closeModal} className='btn-about'>
                <X color='#fff' size={18}/>
            </ButtonClose>  

        </Container>
    )
}