import axios from "axios";

async function fetchData() {
    try {
        const response = await axios.get('https://api.easysale.io/service-get-reseller.php?token=6661ef56a8fd19ff03ce9e30dfd0f991a5bb76d4');
        return response.data;
    } catch (error) {
        console.error(error);
    }	
}

export default fetchData;