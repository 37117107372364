import { 
    AboutTexts,
    AboutContainer,
    Content,
    Title,
    ContentInformations,
    ButtonMoreInfo,
    AboutImg,
    Banner
} from "./styles";
import BannerAbout from '../../assets/AnaTheiss_banner_about.png';

export default function About({openModalAbout}) {
    return (
        <AboutContainer>
            <Content>
                <AboutTexts>
                    <Title>Sobre nós</Title>
                    <ContentInformations>
                        Na <strong>Ana Theiss Semijoias,</strong> acreditamos que cada joia conta uma história. Fundada em 2017, nossa missão é oferecer uma mercadoria de alta qualidade e versátil.<br/>
                        É por meio das semijoias que mulheres encontram sua beleza, valorização e autoestima. Sentindo-se elegantes, sofisticadas, fortes e lindas.<br/>
                        Combinando beleza e inovação, estamos comprometidos em trazer para vocês clientes e vendedoras o melhor do mercado das SEMIJOIAS.
                    </ContentInformations>
                </AboutTexts>
                <ButtonMoreInfo onClick={openModalAbout}>+ informações</ButtonMoreInfo>
            </Content>
            <Content>
                <AboutImg>
                    <Banner src={BannerAbout} alt="" />
                </AboutImg>
            </Content>
        </AboutContainer>
    )
}