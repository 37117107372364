import { ButtonMoreInfo } from "../about/styles";
import {
    WholesaleContainer,
    ContentWhole,
    CardContent,
    InformationsContent,
    Title,
    ImgWhole,
    Information
} from "./styles";
import BannerWholesale from "../../assets/AnaTheiss_banner_team.png";

export default function Wholesale({ onClickWholesale, onClickGuarantee }) {
    return (
        <WholesaleContainer>
            <ContentWhole>
                <CardContent>
                    <InformationsContent>
                        <Title>Seja uma cliente de atacado</Title>
                        <Information>Pra você que quer ter uma margem de lucratividade superior ao consignado, pode se tornar uma cliente de ATACADO.</Information>
                        <ButtonMoreInfo onClick={onClickWholesale}>
                            + informações
                        </ButtonMoreInfo>
                    </InformationsContent>
                </CardContent>

                <CardContent>
                    <InformationsContent>
                        <Title>Garantia</Title>
                        <Information>
                            Oferecemos garantia de 1 ANO para banho e defeito de fábrica.
                            Qualquer eventual problema com sua peça, entre em contato com a vendedora que você adquiriu a mercadoria.
                        </Information>
                        <ButtonMoreInfo onClick={onClickGuarantee}>
                            + informações
                        </ButtonMoreInfo>
                    </InformationsContent>
                </CardContent>
            </ContentWhole>

            <ContentWhole>
                <CardContent>
                    <InformationsContent className="card-team">
                        <Title>Nossa Equipe de Vendedoras</Title>
                        <Information>Conheça Nossas Vendedoras. <br/>Nossa equipe de vendedoras está aqui para oferecer um atendimento personalizado e ajudar você a encontrar a joia perfeita.</Information>
                    </InformationsContent>
                </CardContent>

                <CardContent>
                    <ImgWhole src={BannerWholesale} alt=""/>
                </CardContent>
            </ContentWhole>
        </WholesaleContainer>
    )
}