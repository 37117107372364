import {
    FooterContainer,
    FooterContent,
    FooterText,
    FooterImg,
    NavHeader,
    UlHeader,
    LiHeader,
    LinkHeader,
    FooterIcons,
    LinkHeaderIcon
} from "./styles"
import { Instagram } from 'lucide-react';

import FooterLogo from "../../assets/AnaTheiss_Logo_at.png";
import WhatsappLogo from '../../assets/AnaTheiss_whatsapp_logo_white.png';
import icon_about from '../../assets/icon_about.png';
import icon_atacado from '../../assets/icon_atacado.png';
import icon_consignado from '../../assets/icon_consignado.png';
import icon_contact from '../../assets/icon_contact.png';
import icon_garantia from '../../assets/icon_garantia.png';

export default function Footer({
    linkAbout,
    linkConsigned,
    linkWholesale,
    linkGuarantee,
    linkTeam,
    linkContact,
    onClick
}) {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterImg>
                    <a href="/">
                        <img src={FooterLogo} alt="" />
                    </a>
                </FooterImg>
                <NavHeader>
                    <UlHeader>
                        <LiHeader>
                            <LinkHeader href={linkAbout} onClick={onClick}>Sobre nós</LinkHeader>
                            <LinkHeaderIcon href={linkAbout} onClick={onClick}>
                                <img src={icon_about} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <LiHeader>
                            <LinkHeader href={linkConsigned} onClick={onClick}>Consignado</LinkHeader>
                            <LinkHeaderIcon href={linkConsigned} onClick={onClick}>
                                <img src={icon_consignado} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <LiHeader>
                            <LinkHeader href={linkWholesale} onClick={onClick}>Atacado</LinkHeader>
                            <LinkHeaderIcon href={linkWholesale} onClick={onClick}>
                                <img src={icon_atacado} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <LiHeader>
                            <LinkHeader href={linkGuarantee} onClick={onClick}>Garantia</LinkHeader>
                            <LinkHeaderIcon href={linkGuarantee} onClick={onClick}>
                                <img src={icon_garantia} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <LiHeader>
                            <LinkHeader href={linkTeam} onClick={onClick}>Vendedoras</LinkHeader>
                            <LinkHeaderIcon href={linkTeam} onClick={onClick}>
                                <img src={icon_garantia} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <LiHeader>
                            <LinkHeader href={linkContact} onClick={onClick}>Contato</LinkHeader>
                            <LinkHeaderIcon href={linkContact} onClick={onClick}>
                                <img src={icon_contact} alt="" />
                            </LinkHeaderIcon>
                        </LiHeader>
                        <FooterIcons>
                            <a href="https://www.instagram.com/anatheisssemijoias/" target="_blank" rel="noreferrer">
                                <Instagram size={20} color="#fff" className="icon" />
                            </a>
                            <a href="https://api.whatsapp.com/send/?phone=5547984043160&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                                <img src={WhatsappLogo} alt="" />
                            </a>
                        </FooterIcons>
                    </UlHeader>
                </NavHeader>
            </FooterContent>
            <FooterText>
                <p>© 2024 Ana Theiss Semi Joias. Todos os direitos reservados.</p>
                <span></span>
                <p>Desenvolvido por <a href="https://wehsoft.com/" target="_blank" rel="noreferrer">Wehsoft Sistemas</a></p>
            </FooterText>
        </FooterContainer>
    )
}