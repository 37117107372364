import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    color: aliceblue;
    position: relative;

    .revenue-images {
        display: flex;
        gap: 10px;
    }

    @media (max-width: 768px) {
        .revenue-images {
            flex-direction: column;
        }

        .revenue-images img {
            height: 300px;
            overflow: auto;
        }   
    }

`;

export const ImgContent = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    .img-mobile {
        display: none;
    }
    
    img {
        width: 100%;
        height: auto;
        max-height: 90vh;
        border-radius: 5px;
    }

    button {
        position: absolute;
        bottom: 0;
        top: 75%;
        right: 63%;
        left: 64%;
        border: none;
        width: 200px;
        max-width: 200px;
        height: 40px;
        border-radius: 5px;

        a {
            width: 100%;
            height: 100%;
            background-color: #ffffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 5px;
            text-decoration: none;
            transition: .25s all ease-in-out;
            font-family: "Montserrat", sans-serif;
            font-size: 17px;
            color: #E1A6A0;
            font-weight: 600;
        }

        a:hover {
            transition: .25s all ease-in-out;
            background-color: #fef1ef;
        }

        @media (max-width: 1000px) {
            left: 61%;
            height: 35px;
            max-width: 175px;

            a {
                font-size: 13px;
            }
        }

        @media (max-width: 767px) {
            bottom: 40%;
            top: 40%;
            right: 50%;
            left: 30%;
            height: 30px;
            max-width: 120px;

            a {
                font-size: 10px;
            }
        }
    }

    @media (max-width: 767px) {
        .img-mobile {
            display: flex;
            max-height: 600px;
        }

        .img-desktop {
            display: none;
        }
    }
`;

export const TextInfo = styled.div`
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
    margin: 20px 10px;
   
    font-family: "Montserrat", sans-serif;

    @media (max-width: 650px) {
        font-size: 10px;
        margin: 5px 0;
    }
`;

export const ButtonClose = styled.button`
    position: absolute;
    top: 2px;
    left: 2px;
    border: none;
    background-color: #0000008a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    transition: .25s all;

    &:hover {
        background-color: #000000ff;
        transition: .25s all;
    }
`;
