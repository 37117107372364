import {
    Content,
    AdvantagesContainer,
    Cards
} from './styles';
import CardIcon_01 from '../../assets/AnaTheiss_icon_advantages_01.png';
import CardIcon_02 from '../../assets/AnaTheiss_icon_advantages_02.png';
import CardIcon_03 from '../../assets/AnaTheiss_icon_advantages_03.png';
import CardIcon_04 from '../../assets/AnaTheiss_icon_advantages_04.png';

export default function Advantages() {
    return (
        <Content>
            <AdvantagesContainer>
                <Cards>
                    <div>
                        <img src={CardIcon_01} alt="" />
                    </div>
                    <div>
                        <p>Peças sofisticadas</p>
                        <span>As semijoias tem conquistado um espaço significativo no mundo da moda. E nós da Ana Theiss semijoias buscamos as mais sofisticadas e modernas pra você.</span>
                    </div>
                </Cards>

                <Cards>
                    <div>
                        <img src={CardIcon_02} alt="" />
                    </div>
                    <div>
                        <p>Qualidade Impecável</p>
                        <span>
                            O encanto das peças não está somente   no seu brilho e sofisticação, e sim na QUALIDADE do seu banho e acabamento.
                            Nossas peças são compostas por um alto teor de OURO 18K.
                        </span>
                    </div>
                </Cards>

                <Cards>
                    <div>
                        <img src={CardIcon_03} alt="" />
                    </div>
                    <div>
                        <p>Versatilidade e Acessibilidade</p>
                        <span>As semijoias permitem que o público no geral tenha acesso   a peças elegantes e com estilo. Podendo acompanhar as tendências com beleza, diversidade e qualidade.</span>
                    </div>
                </Cards>

                <Cards>
                    <div>
                        <img src={CardIcon_04} alt="" />
                    </div>
                    <div>
                        <p>Compromisso</p>
                        <span>Nossa empresa tem o compromisso de apresentar a vocês peças versáteis, modernas, sofisticadas e de qualidade.</span>
                    </div>
                </Cards>
            </AdvantagesContainer>
        </Content>
    )
}