import styled from "styled-components";

export const WholesaleContainer = styled.div`
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 950px) {
        height: auto;
    }
`;

export const ContentWhole = styled.div`
    width: 95%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 5px;

    @media (max-width: 950px) {
        flex-direction: column;
        height: auto;
        width: 100%;

        &:last-child {
            flex-direction: column-reverse;

            &:last-child {
                padding: 0;
            }
        }
    }
`;

export const CardContent = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .card-team {
        height: 60%;
    }

    @media (max-width: 950px) {
        width: 100%;
        gap: 20px;

        &:last-child {
            padding: 0;
        }
    }
`;

export const InformationsContent = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-width: 950px) {
        width: 95%;
        gap: 20px;
    }
`;

export const Title = styled.span`
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #E1A6A0;
    padding: 0 10px;

    @media (max-width: 950px) {
        font-size: 20px;
    }
`;

export const Information = styled.p`
    padding: 0 20px;
    color: #8E8E8E;
    text-align: center;
    font-size: 16.5px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
`;

export const ImgWhole = styled.img`
   width: 400px;
   height: auto;
   object-fit: cover;

   @media (max-width: 450px) {
        width: 100%;
   }
`;