import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 100%;
    margin: 35px 0 5px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
`;

export const FooterContent = styled.div`
    width: 100%;
    height: 80%;
    background-color: #E1A6A0;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const FooterImg = styled.div`
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a, img {
        width: auto;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 950px) {
        display: none;
    }
`;

export const FooterText = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;

    span {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #E1A6A0;
    }

    p a {
        text-decoration: none;
        transition: .3s all;

        &:hover {
            color: #E1A6A0;
            text-decoration: underline;
            transition: .3s all;
        }
    }

    @media (max-width: 600px) {
        font-size: 8px;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        height: 40%;

        span {
            display: none;
        }
    }
`;

export const NavHeader = styled.nav`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 950px) {
        width: 100%;
    }
`;

export const UlHeader = styled.ul`
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
`;

export const LiHeader = styled.li`
    position: relative;
    width: auto;
    max-width: 125px;
`;

export const LinkHeader = styled.a`
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 300;
    font-size: 17px;
    text-transform: uppercase;
    transition: .35s all;
    cursor: pointer;

    &:hover {
        color: #C39B6A;
        transition: .35s all;
    }

    @media (max-width: 1230px) {
        font-size: 13px;
    }

    @media (max-width: 1010px) {
        font-size: 11.5px;
    }

    @media (max-width: 950px) {
        display: none;
    }
`;

export const LinkHeaderIcon = styled.a`
    text-decoration: none;
    transition: .35s all;
    cursor: pointer;
    display: none;

    &:hover {
        color: #C39B6A;
        transition: .35s all;
    }

    @media (max-width: 950px) {
        display: flex;
    }
`;

export const FooterIcons = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    img {
        width: 20px;
        height: auto;
        object-fit: cover;
        cursor: pointer;
        transition: .3s all;

        &:hover {
            opacity: .5;
            transition: .3s all;
        }
    }

    .icon {
        cursor: pointer;
        transition: .3s all;

        &:hover {
            opacity: .5;
            transition: .3s all;
        }
    }

    @media (max-width: 950px) {
        display: none;
    }
`;